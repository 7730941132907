<template>
    <div>
        <div style="text-align: right; margin-bottom: 10px">
            <Button @click="() => showCreateModal()" type="primary">创建</Button>
        </div>
        <Table stripe :columns="TableLIst.title" :data="TableLIst.list">
            <template slot-scope="{ row }" slot="operation">
                <Button @click="() => showEditModal(row)" type="primary" size="small" style="margin-right: 10px">编辑</Button>
                <Poptip transfer confirm title="确定是否删除?">
                    <Button disabled type="error" size="small">删除</Button>
                </Poptip>
            </template>
            <template slot-scope="{ row }" slot="displayTags">
                <i-Switch @on-change="() => handleSwitch(row)" v-model="row.displayTags"></i-Switch>
            </template>
        </Table>
        <Card style="text-align: right">
            <Page ref="page" @on-change="(value) => handleCouponOrderList(value)" :page-size="TableLIst.size" :total="TableLIst.total"></Page>
        </Card>
        <Modal :title="modalType" width="600" :mask-closable="false" :closable="false" v-model="createModal">
            <Form ref="form" :model="form" :label-width="120">
                <FormItem prop="name" label="分组名称">
                    <Input class="w_300" v-model="form.name"></Input>
                </FormItem>
                <FormItem prop="sortnum" label="排序">
                    <Input type="number" class="w_300" v-model.number="form.sortnum"></Input>
                </FormItem>
                <FormItem prop="showModel" label="显示模式">
                    <RadioGroup v-model="form.showModel" type="button" button-style="solid">
                        <Radio label="常规"></Radio>
                        <Radio label="条件"></Radio>
                    </RadioGroup>
                </FormItem>
                <div v-if="form.showModel == '条件'" style="display: flex">
                    <FormItem prop="cond.op" label="">
                        <Cascader style="width: 290px" :data="data" v-model="form.cond.op"></Cascader>
                    </FormItem>
                    <FormItem prop="cond.value" label="" :label-width="10">
                        <InputNumber :min="0" style="width: 60px" v-model="form.cond.value"></InputNumber>
                    </FormItem>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="() => (createModal = false)">取消</Button>
                <Button @click="() => handleCreate()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { GetGoodBaseList, CreateGoodBase, EditGoodBase, GetPresetValue, MerGroupType } from '@/request/api';
import { download } from '@/utils/table_download.js';
export default {
    name: 'Groupmanagement',
    data() {
        return {
            stateList: ['在售', '停售'],
            formEdit: {
                state: '',
                purchas: [], //供应商选择 ["总部采购","影院自采"] 多选
                adcunit: [], //其
            },
            form: {
                name: '',
                sortnum: '',
                showModel: '常规',
                cond: {
                    op: '',
                    value: 0,
                },
            },
            addObj: {
                unit: '',
                val: '',
            },
            createtitle: [
                {
                    key: 'unit',
                    title: '单位',
                },
                {
                    key: 'val',
                    title: '换算量',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                    width: 100,
                },
            ],
            TableLIst: [],
            createModal: false,
            editModal: false,
            PresetValue: '',
            EditDesc: '',
            modalType: '创建',
            currentRow: '',

            value1: [],
            data: [],
        };
    },
    watch: {
        createModal(value) {
            if (!value) {
                this.$refs['form'].resetFields();
            }
        },
        editModal() {
            this.$refs['formEdit'].resetFields();
        },
    },
    created() {
        this.handleTableLIst();
    },
    methods: {
        /**
         * 切换显示状态
         */
        async handleSwitch(value) {
            const { _id, name } = value;
            await MerGroupType({
                type: '修改可视',
                id: _id,
            });
            this.handleTableLIst(this.$refs.page.currentPage);
        },
        /**
         * 表格新增
         */
        async handleCreate() {
            if (this.form.sortnum <= 0) {
                this.$Message.error('数量不能小于0');
                return;
            }
            //console.log(this.form);
            if (this.form.name == '' || this.form.sortnum == '') return;
            let passData = JSON.parse(JSON.stringify(this.form));
            //处理首次常规创建
            if (this.form.showModel == '条件') {
                passData.cond.op = passData.cond.op.join('.');
            }
            passData['type'] = '创建分组';
            if (this.modalType == '编辑') {
                passData['type'] = '修改分组';
                passData['id'] = this.currentRow._id;
            }
            if (this.form.showModel == '常规') {
                delete passData['cond'];
            }
            let res = await MerGroupType(passData);
            if (res.errcode != 0) return;
            this.handleTableLIst();
            this.createModal = false;
        },
        /**
         * 展开编辑弹窗
         */
        async showEditModal(row) {
            this.form.cond.op = [];
            this.form.cond.value = 0;
            await this.handleGetPresetValue();
            this.modalType = '编辑';
            const { name, sortnum, showModel, cond } = row;
            this.createModal = true;
            this.currentRow = row;
            this.form.name = name;
            this.form.sortnum = sortnum;
            this.form.showModel = showModel;
            if (cond.op || cond.value) {
                this.form.cond.op = cond.op.split('.');
                this.form.cond.value = cond.value;
            }
        },
        /**
         * 展开创建弹窗
         */
        async showCreateModal() {
            await this.handleGetPresetValue();
            this.modalType = '创建';
            this.createModal = true;
        },

        /**
         * 分页
         */
        async handleTableLIst(page = 1) {
            let res = await MerGroupType({
                type: '获取列表', //操作类型 ['获取列表','创建分组','修改分组','修改可视'] 四选一
                page: page, //分页页码，数字类型
            });
            if (res.errcode != 0) return;
            res.data.title.forEach((item) => {
                if (item.key == 'displayTags') {
                    item['slot'] = 'displayTags';
                }
            });
            res.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
            });

            this.TableLIst = res.data;
        },
        /**
         * 获取预设值配置
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({
                type: '卖品管理v3',
            });
            if (res.errcode != 0) return;
            this.data = res.data.showModel;
        },
    },
};
</script>
<style>
.ivu-form-item {
    margin-bottom: 6px;
}
</style>
