<template>
    <div>
        <Card v-if="listData.length > 0">
            <div class="flex">
                <Menu theme="light" :active-name="activeName" @on-select="changeNav">
                    <MenuGroup title="须知管理">
                        <MenuItem v-for="(item, index) in listData" :key="index" :name="index">
                            {{ item.label }}
                        </MenuItem>
                    </MenuGroup>
                </Menu>
                <div class="p-10">
                    <Form ref="detail" :model="detail" :rules="ruleDetail" label-position="top">
                        <FormItem label="协议内容" prop="content">
                            <fuwenben :useImg="useImg" :injectValue="detail.richText" :showTitle="false" @changevalue="(value) => (detail.richText = value)" />
                        </FormItem>
                        <FormItem label="启用" prop="isshow">
                            <i-Switch v-model="detail.isshow"></i-Switch>
                        </FormItem>
                        <FormItem class="text-right">
                            <Button @click="saveDetail" type="primary">保存</Button>
                        </FormItem>
                    </Form>
                </div>
            </div>
        </Card>
    </div>
</template>
<script>
import { OperationNotice } from '@/request/api.js';
import fuwenben from '@/components/fuwenben.vue';
export default {
    name: 'userNotice',
    components: {
        fuwenben,
    },
    data() {
        return {
            activeName: 0,
            listData: [],
            detail: {
                richText: '',
                isshow: false,
            },
            ruleDetail: {
                richText: { required: 'true', trigger: 'blur', message: ' ' },
                isshow: { required: 'true', trigger: 'blur', type: 'boolean', message: ' ' },
            },
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        /**
         * 保存详情
         */
        saveDetail() {
            this.$refs['detail'].validate(async (valid) => {
                console.log(valid);
                if (valid) {
                    const { content, isshow, _id, richText } = this.detail;

                    let res = await OperationNotice({
                        id: _id,
                        exeType: '修改内容',
                        isshow,
                        richText,
                    });
                    if (res.errcode == 0) {
                        this.$Message.success('保存成功');
                    }
                    this.changeNav(this.activeName);
                }
            });
        },
        /**
         * 获取列表详情
         */
        async changeNav(value) {
            this.activeName = value;
            //取出当前的id
            let id = this.listData[value].value;
            //执行获取内容
            let res = await OperationNotice({
                exeType: '获取内容',
                id,
            });
            if (res.errcode != 0) return;
            res.data.content = res.data.content.join('\n');
            this.detail = res.data;
        },
        /**
         * 数据获取
         */
        async fetchData() {
            const res = await OperationNotice({
                exeType: '获取列表',
            });
            if (res.errcode != 0) return;
            this.listData = res.data;
            if (res.data.length > 0) {
                this.changeNav(0);
            }
        },
    },
};
</script>
