<template>
    <div v-if="list.length > 0">
        <h1 class="font-bold">{{ list[activeFilmIndex].Movie.movieName }}</h1>
        <div class="text-right">
            <Button @click="() => beSome()" type="error">更新同步</Button>
        </div>
        <div class="flex mt-5 overflow-x-scroll">
            <div @click="() => changeFilm(index)" v-for="(item, index) in list">
                <div class="mr-5 cursor-pointer h-41 w-30" style="border: 3px solid orange" v-if="item.Movie.Film[0] && activeFilmIndex == index">
                    <img :alt="item.Movie.movieName" class="h-40 w-28" :src="item.Movie.Film[0].img" />
                </div>
                <div class="mr-5 cursor-pointer h-41 w-30" style="border: 3px solid #fff" v-if="item.Movie.Film[0] && activeFilmIndex != index">
                    <img :alt="item.Movie.movieName" class="h-40 w-28" :src="item.Movie.Film[0].img" />
                </div>
            </div>
        </div>
        <div class="mt-5 mb-5">
            <div class="flex">
                <div class="mr-5" v-for="(item, index) in list[activeFilmIndex].ShowList" :key="index">
                    <div
                        @click="() => changeTabDate(index)"
                        :class="['bg-white p-2 rounded-md cursor-pointer ', activeDateIndex == index && 'bg-yellow-400 text-white']"
                    >
                        {{ item.showDate }}
                    </div>
                </div>
            </div>
        </div>
        <Table :columns="title" :data="list[activeFilmIndex].ShowList[activeDateIndex].showlist">
            <template slot-scope="{ row, index }" slot="movieName">
                <div>{{ row.movieName }}</div>
                <div style="display: flex; justify-content: center">
                    <div
                        v-for="(item, index) in row.tabs"
                        :style="`margin-right: 10px;
                            margin-bottom:3px;
                            position: relative;
                            padding: 0px 5px;
                            background-color:${item.backgroundColor};
                            color: ${item.color};
                            text-align: center;
                            border: 1px solid ${item.borderColor};
                            display: flex;
                            justify-content: center;`"
                    >
                        <div>{{ item.text }}</div>
                    </div>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="priceHit">
                <div style="color: orange">{{ row.priceHit }}</div>
            </template>
            <template slot-scope="{ row, index }" slot="status">
                <div>
                    <Tag v-if="row.cmsOpen == false" color="red">系统停售</Tag>
                </div>
                <!--<i-Switch
                    v-if="row.cmsOpen"
                    size="small"
                    @on-change="() => changSwitch(row)"
                    v-model="list[activeFilmIndex].ShowList[activeDateIndex].showlist[index].status"
                    ></i-Switch>-->
                <i-Switch v-if="row.cmsOpen" size="small" @on-change="() => changSwitch(row)" v-model="row.status"></i-Switch>
            </template>
            <template slot-scope="{ row, index }" slot="basePrice">
                {{ row.basePrice.toFixed(2) }}
            </template>
            <template slot-scope="{ row, index }" slot="memberPrice">
                {{ row.memberPrice.toFixed(2) }}
            </template>
            <template slot-scope="{ row, index }" slot="opreate">
                <Dropdown @on-click="(value) => dropDownMenuSelect(row, value)" trigger="click" transfer>
                    <Button size="small" type="primary">
                        操作
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem name="修改价格">修改价格</DropdownItem>
                        <DropdownItem name="会员策略">会员策略</DropdownItem>
                        <DropdownItem name="清除自定义价格">清除自定义价格</DropdownItem>
                        <DropdownItem name="设置小标">设置小标</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </template>
        </Table>
        <Modal v-model="show" :maskClosable="false" :closable="false">
            <div v-for="(item, index) in tableRowData.memberlv" :key="index">
                <div class="flex">
                    <div class="w-28">{{ item.levelName }}</div>
                    <div class="w-28">{{ item.preferenceValue }}</div>
                </div>
            </div>
        </Modal>
        <Modal v-model="editModal" :closable="false" :maskClosable="false">
            <Form ref="editForm" :model="editForm" :rules="ruleEditForm" :label-width="100">
                <FormItem label="会员价" prop="memberPrice">
                    <Input placeholder="自定义价格区间1-999" v-model.number="editForm.memberPrice" />
                </FormItem>
                <FormItem label="售价" prop="basePrice">
                    <Input placeholder="自定义价格区间1-999" v-model.number="editForm.basePrice" />
                </FormItem>
            </Form>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => hiddeMe()">取消</Button>
                <Button @click="() => savePriceEdit()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal v-model="resetModal" :closable="false" :maskClosable="false">
            <div>确认是否清除自定义价格?</div>
            <div slot="footer" class="flex justify-end">
                <Button @click="() => hiddeResetModal()">取消</Button>
                <Button @click="() => resetPrice()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal v-model="setSmallTagModal" :closable="false" :maskClosable="false">
            <Form ref="formSmallTag" :model="formSmallTag" :rules="ruleFormSmallTag" :label-width="100">
                <FormItem label="背景颜色" prop="backgroundColor">
                    <ColorPicker v-model="formSmallTag.backgroundColor" />
                </FormItem>
                <FormItem label="文字颜色" prop="color">
                    <ColorPicker v-model="formSmallTag.color" />
                </FormItem>
                <FormItem label="边框颜色" prop="borderColor">
                    <ColorPicker v-model="formSmallTag.borderColor" />
                </FormItem>
                <FormItem label="标签内容" prop="text">
                    <Input placeholder="请填写内容" v-model.number="formSmallTag.text" />
                </FormItem>

                <FormItem label="" style="text-align: right">
                    <Button @click="addSmallTag">添加</Button>
                </FormItem>
                <div style="display: flex">
                    <div
                        v-for="(item, index) in tags"
                        :style="`margin-right: 10px;
                            position: relative;
                            padding: 0px 5px;
                            background-color:${item.backgroundColor};
                            color: ${item.color};
                            text-align: center;
                            border: 1px solid ${item.borderColor};
                            display: flex;
                            justify-content: center;`"
                    >
                        <div>{{ item.text }}</div>
                        <div @click="() => removeTag(index)" style="position: absolute; right: -10px; top: -10px; font-size: 18px">
                            <Icon style="color: #000" type="ios-close-circle" />
                        </div>
                    </div>
                </div>
            </Form>
            <div class="flex justify-end" slot="footer">
                <Button @click="() => hiddeMe()">取消</Button>
                <Button @click="() => submitSmallTag()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetPlan, SetCmstPlan, SetPlanTag } from '@/request/api';
export default {
    name: 'filmPriceV2',
    data() {
        return {
            tableRowData: '',
            show: false,
            value: '',
            list: [],
            activeFilmIndex: 0,
            activeDateIndex: 0,
            title: [
                {
                    title: '场次号',
                    key: 'seqNo',
                },
                {
                    title: '影厅',
                    key: 'screenName',
                },
                {
                    title: '影片名',
                    key: 'movieName',
                    slot: 'movieName',
                    width: 300,
                    align: 'center',
                },
                {
                    title: '语言',
                    key: 'lang',
                },
                {
                    title: '放映时间',
                    key: 'showTime',
                },
                {
                    title: '售卖状态',
                    key: 'status',
                    slot: 'status',
                },
                {
                    title: '售价',
                    key: 'basePrice',
                    slot: 'basePrice',
                },
                {
                    title: '会员价',
                    key: 'memberPrice',
                    slot: 'memberPrice',
                },
                {
                    title: '命中策略',
                    key: 'priceHit',
                    slot: 'priceHit',
                },

                {
                    title: '操作',
                    key: 'opreate',
                    slot: 'opreate',
                },
            ],
            editModal: false,
            editForm: {
                memberPrice: '',
                basePrice: '',
            },
            ruleEditForm: {
                memberPrice: { required: true, min: 1, max: 999, message: ' ', type: 'number' },
                basePrice: { required: true, min: 1, max: 999, message: ' ', type: 'number' },
            },
            resetModal: false,
            setSmallTagModal: false,
            formSmallTag: {
                color: '#ffffff',
                backgroundColor: '#ff0000',
                borderColor: '#ff0000',
                text: '',
            },
            ruleFormSmallTag: {
                color: { required: true, message: ' ' },
                backgroundColor: { required: true, message: ' ' },
                borderColor: { required: true, message: ' ' },
                text: { required: true, message: ' ' },
            },
            tags: [],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        removeTag(index) {
            this.tags = this.tags.filter((item, index1) => index1 != index);
        },
        addSmallTag() {
            if (this.tags.length > 2) {
                this.$Message.warning('标签数量不能超过三个');
                return;
            }
            this.$refs['formSmallTag'].validate(async (valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.formSmallTag));
                    this.tags.push(data);
                }
            });
        },
        /**
         * 提交小表弹窗
         */
        async submitSmallTag() {
            let { seqNo } = this.tableRowData;
            let passdata = {
                type: '场次标签',
                seqNo: seqNo,
                tags: this.tags,
            };

            let res = await SetPlanTag(passdata);
            if (res.errcode != 0) return;
            this.tableRowData.tabs = this.tags;
            this.hiddeMe();
            this.setSmallTagModal = false;
        },
        /**
         * 保存设置小标弹窗
         */
        async saveSmallTag() {
            this.$refs['formSmallTag'].validate(async (valid) => {
                if (valid) {
                    //保存到本地
                }
            });
        },
        /**
         * 隐藏弹窗 清除自定义价格
         */
        hiddeResetModal() {
            this.resetModal = false;
        },
        /**
         * 清除自定义价格
         */
        async resetPrice() {
            const { seqNo } = this.tableRowData;
            let res = await SetCmstPlan({ seqNo, setType: '清除自定义价格' });
            if (res.errcode != 0) return;
            this.resetModal = false;
            this.fetchData();
        },
        /**
         * 隐藏弹窗 自定义价格
         */
        hiddeMe() {
            this.setSmallTagModal = false;
            this.editModal = false;
            this.$refs['formSmallTag'].resetFields();
            this.$refs['editForm'].resetFields();
        },
        /**
         * 自定义价格 弹窗 保存
         */
        savePriceEdit() {
            this.$refs['editForm'].validate(async (valid) => {
                if (valid) {
                    const { memberPrice, basePrice } = this.editForm;
                    const { seqNo } = this.tableRowData;
                    let passdata = {
                        seqNo,
                        setType: '自定义价格',
                        memberPrice,
                        basePrice,
                    };
                    let res = await SetCmstPlan(passdata);
                    if (res.errcode != 0) return;
                    this.editModal = false;
                    this.$refs['editForm'].resetFields();
                    this.fetchData();
                }
            });
        },
        /**
         * 小程序开售切换
         */
        async changSwitch(value) {
            const { seqNo } = value;
            let passdata = {
                setType: '停售开售',
                seqNo,
            };
            let res = await SetCmstPlan(passdata);
            if (res.errcode != 0) return;
            this.fetchData();
        },
        async dropDownMenuSelect(data, value) {
            this.tableRowData = data;
            const { memberPrice, basePrice } = data;
            switch (value) {
                case '修改价格':
                    this.editForm.memberPrice = memberPrice;
                    this.editForm.basePrice = basePrice;
                    this.editModal = true;
                    break;
                case '会员策略':
                    this.show = true;
                    break;
                case '清除自定义价格':
                    this.resetModal = true;
                    break;
                case '设置小标':
                    this.tags = JSON.parse(JSON.stringify(this.tableRowData.tabs));
                    this.setSmallTagModal = true;
                    break;
            }
        },
        /**
         * 切换电影
         */
        async changeFilm(index) {
            this.initTabDateIndex();
            this.activeFilmIndex = index;
        },
        /**
         * 切换当前影片日期标签
         */
        changeTabDate(index) {
            this.activeDateIndex = index;
        },
        /**
         * 获取所有影片数据
         */
        async fetchData() {
            let res = await GetPlan({ uptype: '后台获取' });
            if (res.errcode != 0) return;
            this.list = res.data.sort((a, b) => b.Movie.movieCode - a.Movie.movieCode);
        },
        /**
         * 初始化日期切换按钮
         */
        initTabDateIndex() {
            if (this.list.length > 0) {
                this.activeDateIndex = 0;
            }
        },
        /**
         * 更新同步 按钮
         */
        async beSome() {
            let res = await GetPlan({ uptype: '强制更新' });
            if (res.errcode != 0) return;
            this.list = res.data.sort((a, b) => b.Movie.movieCode - a.Movie.movieCode);
            this.$Message.success('更新成功');
        },
    },
};
</script>
