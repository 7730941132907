<template>
    <div class="page-account" style="position: relative">
        <div style="z-index: 3" class="container containerBig">
            <div style="overflow: hidden" class="index_from page-account-container from-wh">
                <div class="page-account-top">
                    <div style="transform: scale(2)" class="page-account-top-logo">商户管理后台</div>
                </div>
                <Form style="margin-top: 30px" ref="ref_login" :model="formInline" :rules="ruleInline">
                    <FormItem prop="username">
                        <Input type="text" v-model="formInline.username" prefix="ios-contact-outline" placeholder="请输入用户名" size="large" />
                    </FormItem>
                    <FormItem prop="password">
                        <Input
                            @on-enter="() => handleSubmit()"
                            autocomplete="new-password"
                            type="password"
                            v-model="formInline.password"
                            prefix="ios-lock-outline"
                            placeholder="请输入密码"
                            size="large"
                        />
                    </FormItem>
                    <FormItem>
                        <Button type="primary" long size="large" @click="handleSubmit()" class="btn">登录</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
        <Modal>
            <div class="captchaBox">
                <div id="captcha" style="position: relative" ref="captcha"></div>
            </div>
        </Modal>
        <div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: black; z-index: 1; opacity: 0.3"></div>
    </div>
</template>
<script>
import '../assets/js/canvas-nest.min';
import '../assets/js/jigsaw.js';
import { md5 } from '../assets/js/md5.js';
import { asdf, login, Set_mcode } from '../request/api.js';

export default {
    name: 'login',
    data() {
        return {
            key: '',
            jigsaw: null,
            formInline: {
                username: '', //账号
                password: '', //密码
            },
            ruleInline: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名！',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码！',
                        trigger: 'blur',
                    },
                    {
                        type: 'string',
                        min: 6,
                        message: '密码长度至少是6位数！',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    async mounted() {
        let key = this.hasKey();
        if (key) {
            this.key = 'test';
            // window.location.replace('/404');
            return;
        }

        // 清空本地存储 可用于用户从品牌选择页返回后初始化本地存储
        localStorage.clear();
        // 组件加载后代码延迟执行
        this.$nextTick(() => {
            // /* eslint-disable */
            let that = this;
            this.jigsaw = jigsaw.init({
                el: this.$refs.captcha,
                onSuccess() {},
                onFail: this.closefail,
                onRefresh() {},
            });
            if (this.screenWidth < 768) {
                document.getElementsByTagName('canvas')[0].removeAttribute('class', 'index_bg');
            } else {
                document.getElementsByTagName('canvas')[0].className = 'index_bg';
            }
        });
    },
    methods: {
        /**
         * 判断是URL否有key存在
         */
        hasKey() {
            let key = this.$route.query.key;
            this.key = key;
            return !key;
        },

        // 账号登录
        handleSubmit() {
            // 执行验证函数
            this.$refs['ref_login'].validate(async (valid) => {
                // 验证通过发送登录请求
                if (valid) {
                    let user = this.formInline.username;
                    let pass = md5(this.formInline.password);
                    let passdata = {
                        user,
                        pass,
                    };
                    if (this.key) {
                        passdata['key'] = this.key;
                    }
                    let { data: res } = await login(passdata);

                    if (res.cms_token) {
                        localStorage.setItem('cms_token', res.cms_token); //设置令牌
                        localStorage.setItem('username', res.user); //设置账号名
                        this.$Message.success('登录成功!');
                        localStorage.setItem('type', res.type); //设置用户级别
                        this.$router.push('/select_stores?type=' + res.type);
                        return;
                    }
                }
            });
        },
    },
};
</script>

<style>
/* 对话框样式 */
.ivu-modal-header {
    border-bottom: unset !important;
}

.ivu-modal-footer {
    border-top: unset !important;
}

/* 对话框样式 */
.index_bg {
    z-index: unset !important;
}

.page-account {
    display: flex;
    width: 100%;
    background-image: url('../assets/images/bg.jpg');
    background-size: cover;
    background-position: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
}

.container {
    height: 350px !important;
    padding: 0 !important;
    border-radius: 12px;
    z-index: 1;
    display: flex;
}

.containerSamll {
    /* width: 56% !important; */
    background: #fff !important;
}

.containerBig {
    width: auto !important;
    background: #fff !important;
}

.index_from {
    padding: 20px 40px 32px 40px;
    height: 400px;
    box-sizing: border-box;
}

.page-account-top {
    padding: 20px 0 !important;
    box-sizing: border-box !important;
    display: flex;
    justify-content: center;
}

.btn {
    background: linear-gradient(90deg, rgba(25, 180, 241, 1) 0%, rgba(14, 115, 232, 1) 100%) !important;
}

.captchaBox {
    width: 310px;
}

input {
    display: block;
    width: 290px;
    line-height: 40px;
    margin: 10px 0;
    padding: 0 10px;
    outline: none;
    border: 1px solid #c8cccf;
    border-radius: 4px;
    color: #6a6f77;
}

.from-wh {
    width: 400px;
}

.pwd {
    text-security: disc;
    -webkit-text-security: disc;
}
</style>
