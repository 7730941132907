<template>
    <div class="p-10">
        <h1>消费记录</h1>
        <Row style="width: 700px">
            <Col span="8">
                <div class="flex">
                    <div>持卡人姓名:</div>
                    <div>{{ tableData.CardData.name }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>等级:</div>
                    <div>{{ tableData.CardData.levelName }}</div>
                </div>
            </Col>
            <Col span="8"></Col>
            <Col span="8">
                <div class="flex">
                    <div>卡号:</div>
                    <div>{{ tableData.CardData.cardNum }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>卡号:</div>
                    <div>{{ tableData.CardData.cardNum }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>绑定号码:</div>
                    <div>{{ tableData.CardData.phoneNum }}</div>
                </div>
            </Col>
            <Col :span="8">
                <div class="flex">
                    <div>余额:</div>
                    <div>{{ tableData.CardData.BASIC }}</div>
                </div>
            </Col>
            <Col span="8">
                <div class="flex">
                    <div>赠送:</div>
                    <div>{{ tableData.CardData.GIVE }}</div>
                </div>
            </Col>
        </Row>
        <div class="flex">
            <div>绑定时间:</div>
            <div>{{ tableData.CardData.bindingTime }}</div>
        </div>

        <Tabs type="card" @on-click="(value) => changeTabs(value)" v-model="currentTab">
            <TabPane v-for="(item, index) in panneConfig" :label="item" :name="item" :index="index">
                <!-- <div class="mb-3 text-right">
                    <Button @click="() => tableDataExport()" size="small" type="primary">导出数据</Button>
                </div> -->
                <div class="mb-3 text-right">
                    <Button v-if="currentTab === '消费记录'" @click="() => send2EmailPop()" size="small" type="primary">发送到邮箱</Button>
                </div>
                <Table size="small" :columns="tableData.title" :data="tableData.list">
                    <!-- <template slot-scope="{ row, index }" slot="operate">
                        <Button @click="() => View(row)" type="primary" size="small">订单详情</Button>
                    </template> -->
                </Table>
                <Card class="text-right">
                    <Page @on-change="(value) => changePage(value)" :total="tableData.total" :pageSize="tableData.limit"></Page>
                </Card>
            </TabPane>
        </Tabs>

        <Modal :mask-closable="false" v-model="send2EmailModal">
            <Form
                :rules="ruleValidateformSend2Email"
                style="padding: 20px 40px 20px 20px"
                ref="formSend2Email"
                :model="formSend2Email"
                :label-width="100"
                label-position="left"
            >
                <FormItem label="查询日期：" prop="time">
                    <DatePicker :options="options3" type="daterange" v-model.object="formSend2Email.time" placeholder="请选择查询日期" style="width: 200px" />
                </FormItem>
                <FormItem label="邮箱地址：" prop="email">
                    <Input v-model="formSend2Email.email" placeholder="请输入邮箱地址"></Input>
                </FormItem>
            </Form>

            <div slot="footer">
                <Button @click="() => hideMe()">返回</Button>
                <Button @click="send2EmailModalConfirm" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { MemCardInfo, GetMemberPayList, GetMemberPayInfoV, memberCardPayData, SendReportEmail } from '@/request/api';
import { download } from '@/utils/table_download.js';
import dayjs from 'dayjs';
export default {
    name: 'historyOrder',
    data() {
        return {
            order: '',
            tableData: {
                list: [],
            },
            tableRowData: '',
            modalTableData: '',
            CardID: '',
            panneConfig: ['消费记录', '消费记录-旧版'],
            currentTab: '消费记录',
            send2EmailModal: false,
            formSend2Email: {
                time: '',
                email: '',
            },
            ruleValidateformSend2Email: {
                time: {
                    required: true,
                    trigger: 'blur',
                    type: 'array',
                },
                email: {
                    required: true,
                    trigger: 'blur',
                    type: 'email',
                },
            },
            options3: {
                disabledDate(date) {
                    return date && date.valueOf() > Date.now();
                },
            },
        };
    },
    mounted() {
        let { CardID } = this.$route.query;
        if (CardID) {
            this.cardID = CardID;
            this.getTableData();
        }
    },
    methods: {
        send2EmailModalConfirm() {
            this.$refs['formSend2Email'].validate(async (valid) => {
                if (valid) {
                    let passdata = {
                        email: this.formSend2Email.email, //电子邮箱example@qq.com
                        type: '会员卡消费明细', //报表类型
                        pass: '', //是否加密
                        content: {
                            cardId: this.cardID, //会员卡ID
                            startTime: dayjs(this.formSend2Email.time[0]).format('YYYY-MM-DD'), //开始时间
                            endTime: dayjs(this.formSend2Email.time[1]).format('YYYY-MM-DD'), //结束时间
                        }, //报表查询内容(不同类型的报表可能不一致)
                    };
                    let res = await SendReportEmail(passdata);
                    if (res.errcode != 0) return;
                    this.$Message.info(res.data);

                    this.hideMe();
                }
            });
        },
        hideMe() {
            this.send2EmailModal = false;
            this.$refs['formSend2Email'].resetFields();
        },
        send2EmailPop() {
            this.send2EmailModal = true;
        },
        send2Email() {},
        /**
         * 表格内的查看
         */
        View(value) {
            const { _id, orderid } = value;
            switch (this.currentTab) {
                case '消费记录-旧版':
                    this.routeTo('orderFundDetail?id=' + orderid);
                    break;

                default:
                    this.routeTo('orderDetail?id=' + _id);
            }
        },
        /**
         * 切换标签页
         */
        async changeTabs(value) {
            this.currentTab = value;
            this.getTableData();
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        /**
         * 导出表格数据
         */
        async tableDataExport() {
            let res = await memberCardPayData({
                CardID: this.cardID,
                all: true,
                gettype: this.currentTab,
            });
            if (res.errcode != 0) return;
            let headers = res.data.title;
            let data = res.data.list;
            download({
                worksheet: '消费记录',
                headers,
                data,
            });
        },
        /**
         * 翻页
         */
        changePage(value) {
            this.getTableData(value);
        },
        /**
         * 表格数据获取
         * @param {*} value
         * @param {*} page
         */
        async getTableData(page = 1) {
            this.tableData.list = [];
            this.tableData.title = [];
            let res = await memberCardPayData({
                CardID: this.cardID,
                page,
                all: false,
                gettype: this.currentTab,
            });
            if (res.errcode != 0) return;
            // res.data.title.push({
            //     title: '操作',
            //     key: 'operate',
            //     slot: 'operate',
            // });
            this.tableData = JSON.parse(JSON.stringify(Object.assign(this.tableData, res.data)));
        },
    },
};
</script>
