<template>
    <div>
        <div style="text-align: right; margin-bottom: 10px">
            <Button @click="() => showCreateModal()" type="primary">创建</Button>
            <Button @click="() => showStoreConfigModal()" style="margin-left: 10px">门店配置</Button>
        </div>
        <Table stripe :columns="TableLIst.title" :data="TableLIst.list">
            <template slot-scope="{ row, index }" slot="operation">
                <Button @click="() => showEditModal(row)" type="primary" size="small" style="margin-right: 10px">编辑</Button>
                <Poptip @on-ok="activeDel(row)" transfer confirm title="确定是否删除?">
                    <Button type="error" size="small">删除</Button>
                </Poptip>
            </template>
            <template slot-scope="{ row }" slot="activityOnline">
                <i-Switch @on-change="() => handleSwitch(row)" v-model="row.activityOnline"></i-Switch>
            </template>
        </Table>
        <Card style="text-align: right">
            <Page ref="page" @on-change="(value) => handleCouponOrderList(value)" :page-size="TableLIst.size" :total="TableLIst.total"></Page>
        </Card>
        <Modal fullscreen :title="modalType" width="1200" :mask-closable="false" :closable="false" v-model="createModal">
            <div style="display: flex">
                <div>
                    <Form ref="form" :model="form" :label-width="120">
                        <FormItem prop="activityName" label="活动名称">
                            <Input class="w_300" v-model="form.activityName"></Input>
                        </FormItem>
                        <FormItem prop="sortnum" label="活动类型">
                            <Select class="w_300" v-model="form.activityTypeKey">
                                <Option v-for="(item, index) in activeTypeList" :key="index" :value="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem prop="activityStart" label="活动开始时间">
                            <DatePicker class="w_300" type="datetime" placeholder="开始时间" v-model="form.activityStart"> </DatePicker>
                        </FormItem>
                        <FormItem prop="activityEnd" label="活动结束时间">
                            <DatePicker class="w_300" type="datetime" placeholder="结束时间" v-model="form.activityEnd"> </DatePicker>
                        </FormItem>
                        <FormItem prop="activityNote" label="备注">
                            <Input class="w_300" type="textarea" v-model="form.activityNote"></Input>
                        </FormItem>
                        <FormItem prop="freeList" label="活动范围">
                            <Select class="w_300" multiple v-model="form.freeList">
                                <Option v-for="(item, index) in activeRange" :key="index" :value="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>

                        <div v-if="form.activityContent">
                            <FormItem prop="activityContent.maxCoupons" label="活动最大领取量">
                                <Input type="number" class="w_300" v-model="form.activityContent.maxCoupons"></Input>
                            </FormItem>
                            <FormItem prop="activityContent.maxPerPerson" label="每人最大领取量">
                                <Input type="number" class="w_300" v-model="form.activityContent.maxPerPerson"></Input>
                            </FormItem>
                            <FormItem prop="activityContent.maxPerDay" label="每人每天最大领取量">
                                <Input type="number" class="w_300" v-model="form.activityContent.maxPerDay"></Input>
                            </FormItem>
                            <FormItem prop="activityContent.isBindPhone" label="领取用户是否需要绑定手机">
                                <i-Switch v-model="form.activityContent.isBindPhone"></i-Switch>
                            </FormItem>
                            <FormItem prop="activityContent.isNewUser" label="仅限新用户领取">
                                <i-Switch v-model="form.activityContent.isNewUser"></i-Switch>
                            </FormItem>
                            <FormItem prop="activityContent.countMode" label="领取计算模式">
                                <Select multiple v-model="form.activityContent.countMode" style="width: 264px">
                                    <Option value="小程序账号">小程序账号</Option>
                                    <Option value="手机号">手机号</Option>
                                </Select>
                            </FormItem>
                        </div>

                        <!--<FormItem prop="showModel" label="显示模式">
                    <RadioGroup v-model="form.showModel" type="button" button-style="solid">
                        <Radio label="常规"></Radio>
                        <Radio label="条件"></Radio>
                    </RadioGroup>
                </FormItem>
                <div v-show="form.showModel == '条件'" style="display: flex">
                    <FormItem prop="cond.op" label="">
                        <Cascader style="width: 290px" :data="data" v-model="form.cond.op"></Cascader>
                    </FormItem>
                    <FormItem prop="cond.value" label="" :label-width="10">
                        <InputNumber :min="0" style="width: 60px" v-model="form.cond.value"></InputNumber>
                    </FormItem>
                </div>-->
                    </Form>
                </div>
                <div style="padding-left: 20px">
                    <div style="display: flex">
                        <div>活动说明：</div>
                        <fuwenben :injectValue="form.activityDesRichText" :showTitle="false" @changevalue="(value) => (form.activityDesRichText = value)" />
                    </div>
                </div>
            </div>
            <Form ref="formInline" :model="formInline" inline>
                <FormItem prop="applyId" label="优惠券批次名称">
                    <Select v-model="formInline.applyId" style="width: 264px">
                        <Option v-for="(item, index) in couponConfigList" :key="index" :value="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="giveValue" label="赠送数量">
                    <Input class="w_300" v-model="formInline.giveValue"></Input>
                </FormItem>
                <FormItem prop="activation" label="激活类型">
                    <Select v-model="formInline.activation" style="width: 264px">
                        <Option value="批次有效期">批次有效期</Option>
                        <Option value="活动有效期">活动有效期</Option>
                        <Option value="固定时间范围">固定时间范围</Option>
                        <Option value="自领券当日起">自领券当日起</Option>
                    </Select>
                </FormItem>
                <FormItem prop="activationTime" label="选择时间">
                    <DatePicker type="daterange" placeholder="选择时间" v-model="formInline.activationTime"> </DatePicker>
                </FormItem>

                <FormItem>
                    <Button style="margin-top: 35px" type="primary" @click="handleSubmit('formInline')">保存</Button>
                </FormItem>
            </Form>

            <Table style="width: 1120px" :columns="columnsCoupons" :data="dataCoupons">
                <template slot-scope="{ row }" slot="label">
                    <div>{{ couponConfigList.find((item) => item.value == row.applyId).span_str }}</div>
                </template>
                <template slot-scope="{ row }" slot="activationTime">
                    <div>{{ row.activationTime.join('-') }}</div>
                </template>
                <template slot-scope="{ row }" slot="activationTime">
                    <div>{{ row.activationTime.join('-') }}</div>
                </template>
                <template slot-scope="{ row, index }" slot="operation">
                    <Button @click="couponDel(index)" type="error" size="small">删除</Button>
                </template>
            </Table>

            <div slot="footer">
                <Button @click="() => hideMe()">取消</Button>
                <Button @click="() => handleCreate()" type="primary">确定</Button>
            </div>
        </Modal>
        <Modal title="门店活动配置" :mask-closable="false" :closable="false" v-model="storeConfigModal">
            <Form ref="formStoreConfig" :model="formStoreConfig">
                <FormItem prop="config.isOpen" label="是否开启">
                    <i-Switch v-model="formStoreConfig.config.isOpen"></i-Switch>
                </FormItem>
                <FormItem prop="config.isOpen" label="允许转发&分享到朋友圈">
                    <i-Switch v-model="formStoreConfig.config.isSharing"></i-Switch>
                </FormItem>
                <FormItem prop="config.closeTheCopy" label="活动内容">
                    <Input type="textarea" class="w_300" v-model="formStoreConfig.config.closeTheCopy"></Input>
                </FormItem>
                <div>(建议尺寸:375X240)</div>
                <div style="display: flex">
                    <div>
                        <div>背景图片</div>
                    </div>
                    <div style="display: flex; align-items: center; margin-left: 10px">
                        <img
                            v-if="formStoreConfig.config.backgroundImage"
                            alt="无数据"
                            style="width: 100px; margin-right: 10px"
                            :src="formStoreConfig.config.backgroundImage"
                        />
                        <qiniuUploadBtn :qiniu_config="qiniu_config" @set_url="($) => (formStoreConfig.config.backgroundImage = $)" />
                    </div>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="() => hideMe()">取消</Button>
                <Button @click="() => confirmStoreActiveConfig()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import {
    GetAppActivityInfo,
    QueryAppActivityList,
    ActivityOnline,
    CreateAppActivity,
    CreateGoodBase,
    EditGoodBase,
    GetPresetValue,
    MerGroupType,
    GetStoresActivityConfig,
    SetStoresActivityConfig,
    getupTK,
    DeleteAppActivity,
    EditAppActivity,
} from '@/request/api';
import { download } from '@/utils/table_download.js';
import fuwenben from '@/components/fuwenben.vue';
import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue';

export default {
    name: 'Groupmanagement',
    components: {
        fuwenben,
        qiniuUploadBtn,
    },
    data() {
        return {
            qiniu_config: {}, //表单状态数据

            stateList: ['在售', '停售'],
            formEdit: {
                state: '',
                purchas: [], //供应商选择 ["总部采购","影院自采"] 多选
                adcunit: [], //其
            },
            form: {
                activityName: '', //活动名称
                activityTypeKey: '',
                activityStart: '',
                activityEnd: '',
                activityNote: '',
                freeList: [], //活动范围——使用气泡
                activityContent: {
                    maxCoupons: null, //活动最大领取量 0表示不限制
                    maxPerPerson: null, //每人最大领取量 0表示不限制
                    maxPerDay: null, //每人每天最大领取量 最小是1
                    couponsList: [],
                },
                activityDesRichText: '', //富文本
            },
            createtitle: [
                {
                    key: 'unit',
                    title: '单位',
                },
                {
                    key: 'val',
                    title: '换算量',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                    width: 100,
                },
            ],
            TableLIst: [],
            createModal: false,
            editModal: false,
            PresetValue: '',
            EditDesc: '',
            modalType: '创建',
            currentRow: '',

            value1: [],
            data: [],
            activeTypeList: [],
            activeRange: [],
            columnsCoupons: [
                {
                    title: '票券批次名称',
                    key: 'label',
                    slot: 'label',
                },
                {
                    title: '赠送数量',
                    key: 'giveValue',
                },
                {
                    title: '激活类型',
                    key: 'activation',
                },
                {
                    title: '日期',
                    key: 'activationTime',
                    slot: 'activationTime',
                },
                {
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                    width: 100,
                    width: 200,
                },
            ],
            dataCoupons: [],
            couponConfigList: [],
            formInline: {
                applyId: '',
                giveValue: null,
                activation: '',
                activationTime: [],
            },
            storeConfigModal: false,
            formStoreConfig: {
                activityTypeKey: 'getCoupons',
                config: {
                    isOpen: true,
                    closeTheCopy: '',
                    backgroundImage: '',
                    isSharing: true,
                },
            },
        };
    },
    watch: {
        createModal(value) {
            if (!value) {
                this.$refs['form'].resetFields();
            }
        },
        editModal() {
            this.$refs['formEdit'].resetFields();
        },
    },
    created() {
        this.handleTableLIst();
        this.handleGetActiveTypeList();
        this.handleGetActiveRange();
        this.handleGetCouponConfig();
        this.to_getupTK();
    },
    methods: {
        async activeDel(row) {
            const { activityId, __v } = row;
            let passdata = {
                activityId,
                __v,
            };
            let res = await DeleteAppActivity(passdata);
            if (res.errcode != 0) return;
            this.handleTableLIst();
        },
        async confirmStoreActiveConfig() {
            let passdata = this.formStoreConfig;
            let res = await SetStoresActivityConfig(passdata);
            if (res.errcode != 0) return;
            this.hideMe();
        },
        // 获取七牛云token
        async to_getupTK() {
            let { data: res_data } = await getupTK();
            this.qiniu_config = res_data.data;
        },
        /**
         * 显示门店活动配置弹窗
         */
        async showStoreConfigModal() {
            let res = await GetStoresActivityConfig({ activityTypeKey: 'getCoupons' });
            if (res.errcode != 0) return;
            this.formStoreConfig.config = res.data;
            this.storeConfigModal = true;
        },
        couponDel(index) {
            this.dataCoupons = this.dataCoupons.filter((item1, index1) => index1 != index);
        },
        handleSubmit() {
            let passdata = {
                ...this.formInline,
            };
            passdata['activationTime'][0] = new Date(passdata['activationTime'][0]).Format('yyyy-MM-dd');
            passdata['activationTime'][1] = new Date(passdata['activationTime'][1]).Format('yyyy-MM-dd');

            this.dataCoupons.push(passdata);
        },
        /**
         * 获取活动类型选项
         */
        async handleGetActiveTypeList() {
            let res = await GetPresetValue({
                type: '应用活动',
            });
            if (res.errcode != 0) return;
            this.activeTypeList = res.data;
        },
        /**
         * 获取活动参加范围
         */
        async handleGetActiveRange() {
            let res = await GetPresetValue({
                type: '应用活动-活动范围',
            });
            if (res.errcode != 0) return;
            this.activeRange = res.data;
        },
        /**
         *领取优惠券
         */
        async handleGetCouponConfig() {
            let res = await GetPresetValue({
                type: '应用活动-领取优惠券',
            });
            if (res.errcode != 0) return;
            this.couponConfigList = res.data;
        },
        /**
         * 切换显示状态
         */
        async handleSwitch(value) {
            const { activityId, activityOnline, __v } = value;
            await ActivityOnline({
                activityId: activityId,
                activityOnline: activityOnline,
                __v,
            });
            this.handleTableLIst(this.$refs.page.currentPage);
        },
        /**
         * 表格新增
         */
        async handleCreate() {
            let passData = this.form;
            let res = null;
            if (this.modalType == '编辑') {
                res = await EditAppActivity(passData);
            } else {
                res = await CreateAppActivity(passData);
            }

            if (res.errcode != 0) return;
            this.handleTableLIst();
            this.createModal = false;
        },
        /**
         * 展开编辑弹窗
         */
        async showEditModal(row) {
            this.modalType = '编辑';
            if (row.activityOnline) {
                this.$Message.warning('上线状态不允许编辑');
                return;
            }
            let res = await GetAppActivityInfo({ activityId: row.activityId });
            if (res.errcode != 0) return;
            let data = res.data;
            data['activityStart'] = new Date(data['activityStart']).Format('yyyy-MM-dd hh:mm:ss');
            data['activityEnd'] = new Date(data['activityEnd']).Format('yyyy-MM-dd hh:mm:ss');
            this.dataCoupons = data.activityContent.couponsList;
            this.currentRow = data;

            this.form = data;
            this.createModal = true;
        },
        /**
         * 展开创建弹窗
         */
        async showCreateModal() {
            await this.handleGetPresetValue();
            this.modalType = '创建';
            this.createModal = true;
        },

        /**
         * 分页
         */
        async handleTableLIst(page = 1) {
            let res = await QueryAppActivityList({
                page: page, //分页页码，数字类型
                size: 10,
            });
            if (res.errcode != 0) return;
            res.data.title.forEach((item) => {
                if (item.key == 'activityOnline') {
                    item['slot'] = 'activityOnline';
                }
            });
            res.data.title.push({
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width: '150',
            });

            this.TableLIst = res.data;
        },
        /**
         * 获取预设值配置
         */
        async handleGetPresetValue() {
            let res = await GetPresetValue({
                type: '卖品管理v3',
            });
            if (res.errcode != 0) return;
            this.data = res.data.showModel;
        },
        hideMe(name) {
            this.dataCoupons = [];
            this.form.activityDesRichText = '';

            this.$refs['form'].resetFields();
            this.$refs['formInline'].resetFields();
            this.storeConfigModal = false;
            this.createModal = false;
        },
    },
};
</script>
<style>
.ivu-form-item {
    margin-bottom: 6px;
}
</style>
