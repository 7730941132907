<template>
    <div>
        <div class="mb-5 text-right">
            <Button @click="() => add()" type="primary">新建</Button>
        </div>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="isDefault">
                <i-Switch :disabled="row.isDefault" v-model="row.isDefault" @on-change="(value) => change(row, value)" />
            </template>
            <template slot-scope="{ row, index }" slot="operate">
                <Button @click="() => edit(row)" size="small" type="primary" class="mr-2">编辑</Button>
                <Poptip transfer confirm title="确定是否删除？" @on-ok="() => remove(row)">
                    <Button size="small" type="error">删除</Button>
                </Poptip>
            </template>
        </Table>
        <Card class="text-right">
            <Page @on-change="(value) => fetchData(value)" :total="tableData.total" :pageSize="tableData.size"></Page>
        </Card>
        <Modal :closable="false" :maskClosable="false" v-model="show">
            <Form class="w-2/3" :label-width="100" ref="form" :model="form" :rules="rulevalidate">
                <FormItem prop="templateName" label="模版名称">
                    <Input v-model="form.templateName" />
                </FormItem>
                <FormItem prop="width" label="模版宽度">
                    <Input type="number" v-model="form.width" />
                </FormItem>

                <FormItem prop="height" label="模版高度">
                    <Input type="number" v-model="form.height" />
                </FormItem>
            </Form>
            <div class="flex justify-end" slot="footer">
                <Button @click="hideMe">取消</Button>
                <Button @click="() => submitAdd()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetTicketTemplateList, CreateTicketTemplate, DeleteTicketTemplate, SetDefaultTicketTemplate } from '@/request/api';
export default {
    name: '',
    data() {
        return {
            show: false,
            tableData: {},
            form: {
                templateName: '',
                width: 800,
                height: 750,
            },
            rulevalidate: {
                templateName: {
                    required: true,
                    trigger: 'blur',
                },
                width: {
                    required: true,
                    trigger: 'blur',
                    type: 'number',
                },
                height: {
                    required: true,
                    trigger: 'blur',
                    type: 'number',
                },
            },
        };
    },
    mounted() {
        this.fetchData();
    },
    beforeDestroy() {},
    methods: {
        async change(row, value) {
            let passdata = {
                templateId: row.templateId,
            };
            let res = await SetDefaultTicketTemplate(passdata);
            if (res.errcode != 0) return;
            this.fetchData(this.tableData.page);
        },
        openNewTab() {
            const data = { message: 'Hello from Vue!', timestamp: new Date() };
            const queryString = new URLSearchParams(data).toString();
            window.open(`/new-page?${queryString}`, '_blank');
        },
        hideMe() {
            this.show = false;
            this.$refs['form'].resetFields();
        },
        submitAdd() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    let passdata = this.form;
                    let res = await CreateTicketTemplate(passdata);
                    if (res.errcode != 0) return;
                    const { templateId, templateName } = res.data;
                    this.show = false;
                    // this.openNewTab(templateId)
                    window.open('/ticketVersionSetTemplateSave?templateId=' + templateId + '&templateName=' + templateName);

                    this.fetchData(this.tableData.page);
                }
            });
        },
        /**
         * 新增 按钮
         */
        add() {
            this.show = true;
            this.modelTitle = '新建';
        },
        async fetchData(page = 1) {
            let res = await GetTicketTemplateList({
                page: page,
                size: 10,
            });
            if (res.errcode != 0) return;
            res.data.title.push({
                title: '操作',
                key: 'operate',
                slot: 'operate',
            });
            let list = res.data.title;
            res.data.list.forEach((item) => {
                if (!item['isDefault']) {
                    item['isDefault'] = false;
                }
            });
            list.forEach((item) => {
                if (item.key == 'isDefault') {
                    item['slot'] = 'isDefault';
                }
            });
            this.tableData = res.data;
        },
        async remove(row) {
            let { templateId } = row;
            let res = await DeleteTicketTemplate({
                templateId: templateId,
            });
            if (res.errcode != 0) return;
            this.fetchData(this.tableData.page);
        },
        edit(row) {
            const { templateId, templateName } = row;
            debugger;
            window.open('/ticketVersionSetTemplateSave?templateId=' + templateId + '&templateName=' + templateName);
        },
    },
};
</script>
