<template>
    <div>
        <Form @submit.native.prevent ref="inlineForm" :rules="inlineFormRules" :model="inlineForm" class="text-right" inline>
            <FormItem prop="pcouponCode">
                <Input @on-enter="fetchData" placeholder="请输入查询券码" v-model="inlineForm.pcouponCode" />
            </FormItem>
            <FormItem>
                <Button @click="() => fetchData()" type="primary">查询</Button>
            </FormItem>
        </Form>
        <Card>
            <div class="flex">
                <div class="w-1 h-5 mr-2 bg-black"></div>
                <h3>基本信息</h3>
            </div>
            <Row class="pl-10 mb-5">
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">券码</div>
                        <div>{{ couponData.couponCode }}</div>
                        <div v-if="couponData.status">
                            <Tag v-if="couponData.status == '已使用'" color="error">{{ couponData.status }}</Tag>
                            <Tag v-if="couponData.status == '未使用'" color="success">{{ couponData.status }}</Tag>
                            <Tag v-if="couponData.status != '已使用' && couponData.status != '未使用'">{{ couponData.status }}</Tag>
                        </div>
                        <Dropdown v-if="couponData._id" @on-click="(value) => dropDownGivCouponMenuSelect(value)" trigger="click" transfer>
                            <Button type="primary">
                                更多操作
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem name="停用">停用</DropdownItem>
                                <DropdownItem name="启用">启用</DropdownItem>
                                <DropdownItem name="作废">作废</DropdownItem>
                                <DropdownItem name="延期">延期</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">批次号</div>
                        <div>{{ couponData.apply }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">票券名称</div>
                        <div>{{ couponData.name }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">票券别名</div>
                        <div>{{ couponData.asname }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">有效期</div>
                        <div v-if="couponData.satrt">
                            {{ new Date(couponData.satrt).Format('yyyy-MM-dd') }}至{{ new Date(couponData.end).Format('yyyy-MM-dd') }}
                        </div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">票券类型</div>
                        <div>{{ couponData.type }}</div>
                    </div>
                </Col>
            </Row>
            <div v-if="couponData.openid" class="flex">
                <div class="w-1 h-5 mr-2 bg-black"></div>
                <h3>绑定信息</h3>
            </div>
            <Row v-if="couponData.openid" class="pl-10 mb-5">
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">绑定用户</div>
                        <div>{{ couponData.openid }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">绑定时间</div>
                        <div>{{ couponData.BindbyTime }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">绑定方式</div>
                        <div>{{ couponData.Bindby }}</div>
                    </div>
                </Col>
            </Row>
            <div v-if="couponData.orderid" class="flex">
                <div class="w-1 h-5 mr-2 bg-black"></div>
                <h3>使用信息</h3>
            </div>
            <Row v-if="couponData.orderid" class="pl-10 mb-5">
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">订单号</div>
                        <div class="flex">
                            <div class="flex mr-2">
                                <div>{{ couponData.orderid }}</div>
                                <div v-if="!couponData.isNew" class="text-red-500">(旧)</div>
                            </div>
                            <Button v-if="couponData.orderShow" @click="() => View(couponData)" size="small"></Button>
                        </div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">订单名称</div>
                        <div>{{ couponData.orderName }}</div>
                    </div>
                </Col>
                <Col v-if="couponData.useInfo.type == '电影票'" span="24">
                    <div class="flex">
                        <div class="w-28">座位号</div>
                        <div>{{ couponData.useInfo.name }}</div>
                    </div>
                </Col>

                <Col span="24">
                    <div class="flex">
                        <div class="w-28">订单所属</div>
                        <div>{{ couponData.cname }}</div>
                    </div>
                </Col>
                <Col span="24">
                    <div class="flex">
                        <div class="w-28">使用时间</div>
                        <div>{{ couponData.userTime }}</div>
                    </div>
                </Col>
            </Row>
        </Card>
        <Modal :mask-closable="false" :closable="false" v-model="modalConfirmGivCoupon" title="提示">
            <div v-if="titleGivCoupon != '延期'">是否{{ titleGivCoupon }}该票券?</div>
            <Form v-else label-width="100" ref="formGivCoupon" :model="formGivCoupon" :rules="ruleGivCoupon">
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">当前票券</div>
                    <div>{{ titleGivCouponTotal.couponCode }}</div>
                </div>
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">原有效期</div>
                    <div>{{ titleGivCouponTotal.end }}</div>
                </div>
                <div class="flex">
                    <div style="width: 100px; text-align: right; padding: 0 10px">延期时间</div>
                    <RadioGroup v-model="buttonSize" type="button">
                        <Radio label="自定义">自定义</Radio>
                        <Radio label="一周">一周</Radio>
                        <Radio label="半月">半月</Radio>
                        <Radio label="一月">一月</Radio>
                        <Radio label="一年">一年</Radio>
                    </RadioGroup>
                </div>
                <FormItem label="延期至">
                    <DatePicker @on-change="(value) => changeGivCoupon(value)" format="yyyy-MM-dd" type="date" :value="cpGivCouponEndTime"> </DatePicker>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="modalConfirmGivCoupon = false">取消</Button>
                <Button @click="() => handleConfirmCoupon()" type="primary">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { QuerycouponCode, SetCouponState } from '@/request/api';
import dayjs from 'dayjs';
export default {
    name: 'couponSeach',
    data() {
        return {
            buttonSize: '自定义',

            inlineForm: {},
            inlineFormRules: {
                pcouponCode: { required: true, trigger: 'blur', message: ' ' },
            },
            couponData: '',
            modalConfirmGivCoupon: false, //持有票券谈出提示
            titleGivCoupon: '',
            titleGivCouponTotal: {}, //表格行数据
            GivCouponDate: '',
            myselfDate: '',
        };
    },
    computed: {
        /**
         * 延期至的 日期显示数据
         */
        cpGivCouponEndTime() {
            //如果是自定义就显示当前时间
            /**
             * if (this.buttonSize == '自定义') {
             *     //(this.GivCouponDate == this.titleGivCoupon.end
             *     return this.GivCouponDate;
             * } else {
             */
            //一周 半月 一月 一年
            //如果是非自定义就显示计算的时间
            if (this.buttonSize == '自定义') {
                return this.myselfDate;
            }
            try {
                let data = {
                    一周: 7,
                    半月: 15,
                    一月: 30,
                    一年: 365,
                };
                let day = data[this.buttonSize];
                this.titleGivCoupon.end;
                let result = dayjs(this.titleGivCouponTotal.end).add(day, 'day').format('YYYY-MM-DD');
                this.myselfDate = result;
                return result;
            } catch (e) {
                /* handle error */
                return -1;
            }
            //            }
        },
    },
    methods: {
        /**
         * 改变延期至日期选择组建
         */
        changeGivCoupon(value) {
            this.GivCouponDate = value;
            this.buttonSize = '自定义';
        },
        async handleConfirmCoupon() {
            let TicketID = this.titleGivCouponTotal._id;
            let SetState = this.titleGivCoupon;
            let passdata = {
                TicketID,
                SetState,
            };
            if (SetState == '延期') {
                //表单必填UpDate
                passdata['UpDate'] = this.cpGivCouponEndTime;
            }
            let res = await SetCouponState(passdata);
            if (res.errcode != 0) return;
            this.$Message.success('操作成功');
            this.modalConfirmGivCoupon = false;
            this.fetchData();
        },

        /**
         * 持有票券下拉选择框 更多选择后
         */
        dropDownGivCouponMenuSelect(value) {
            let row = this.couponData;
            this.titleGivCouponTotal = row;
            this.GivCouponDate = row.end;
            this.myselfDate = row.end;
            this.titleGivCoupon = value;
            this.buttonSize = '自定义';
            this.modalConfirmGivCoupon = true;
        },

        /**
         * 表格内的查看
         */
        View(value) {
            const { isNew, Order_ID: _id, orderid } = value;
            if (isNew) {
                this.routeTo('orderDetail?id=' + _id);
                return;
            }
            this.routeTo('orderFundDetail?id=' + orderid);
        },
        /**
         * 路由跳转
         */
        routeTo(value) {
            window.open(value);
        },
        async fetchData() {
            this.$refs['inlineForm'].validate(async (valid) => {
                if (valid) {
                    const { pcouponCode } = this.inlineForm;
                    let res = await QuerycouponCode({ pcouponCode });
                    if (res.errcode != 0) return;
                    this.couponData = res.data;
                }
            });
        },
    },
};
</script>
