<template>
    <div>
        <iframe ref="iframe" src="https://wed.ruiziyou.com/film.html" :width="width" :height="height"></iframe>
    </div>
</template>
<script>
import { GetTicketTemplateInfo, UpdateTicketTemplate } from '@/request/api';
export default {
    name: '',
    data() {
        return {
            iframeSrc: '',
            sourceData: {
                fields: [
                    {
                        title: '影院名称',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 40,
                        bold: true,
                        data: '测试影院名称',
                        show: true,
                        key: 'cinemaName',
                    },
                    {
                        title: '放映厅',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 30,
                        bold: true,
                        data: '测试影厅',
                        show: true,
                        key: 'cinema',
                    },
                    {
                        title: '放映日期',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 28,
                        bold: true,
                        data: '2024-01-01',
                        show: true,
                        key: 'screeningDay',
                    },
                    {
                        title: '放映时间',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 28,
                        bold: true,
                        data: '22:00',
                        show: true,
                        key: 'screeningTime',
                    },
                    {
                        title: '座位号',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 20,
                        bold: false,
                        data: '01排01座',
                        show: true,
                        key: 'seatNumber',
                    },
                    {
                        title: '影片名称',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 38,
                        bold: true,
                        data: '测试影片名称',
                        show: true,
                        key: 'movieName',
                    },
                    {
                        title: '票号',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 20,
                        bold: false,
                        data: '000000000000000',
                        show: true,
                        key: 'ticketNumber',
                    },
                    {
                        title: '票价',
                        type: 'text',
                        x: 20,
                        y: 20,
                        color: '#000',
                        fontSize: 20,
                        bold: false,
                        data: '35元',
                        show: true,
                        key: 'ticketPrice',
                    },
                    {
                        title: '验证二维码',
                        type: 'toqrcode',
                        x: 20,
                        y: 20,
                        width: 200,
                        height: 200,
                        data: '二维码',
                        show: true,
                        key: 'verifyQRCode',
                    },
                    {
                        title: '服务费',
                        type: 'text',
                        x: 100,
                        y: 100,
                        color: '#000',
                        fontSize: 20,
                        bold: false,
                        data: '3.00元',
                        show: true,
                        key: 'serviceFee',
                    },
                ],
                templateName: '默认票版002',
                canvasConfig: {
                    width: 800,
                    height: 750,
                },
                mcode: '44001631',
                buid: '8de7d7fc-6256-4dbc-afca-6704cca9f3aa',
                createUser: '测试用户',
                updateUser: '测试人员1',
                createdAt: '2025-01-06 22:05',
                updatedAt: '2025-01-09 20:37',
                __v: 22,
                isDefault: true,
                templateId: '677be311cf23ceafc6786789',
            },
            receivedData: '',
            width: 0,
            height: 0,
        };
    },
    async mounted() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.width = width;
        this.height = height;

        window.addEventListener('message', this.handleMessage);

        const iframe = this.$refs.iframe;
        iframe.addEventListener('load', async () => {
            // await this.getTemplateDetail();
            const message = { message: JSON.stringify(this.sourceData), timestamp: new Date() };
            iframe.contentWindow.postMessage(message, '*');
        });
    },
    beforeDestroy() {
        // 移除事件监听器以防止内存泄漏
        window.removeEventListener('message', this.handleMessage);
    },
    methods: {
        async getTemplateDetail() {
            const { templateId, templateName } = this.$route.query;
            let res = await GetTicketTemplateInfo({ templateId });

            if (res.errcode != 0) return;
            this.sourceData = res.data;
        },
        async handleMessage(event) {
            // 可选：验证来源以确保安全
            // if (event.origin !== 'http://trusted-origin.com') return;

            // 处理接收到的数据
            if (event.data.type == 'film') {
                console.log('Message received from iframe:', event.data);
                this.receivedData = JSON.parse(event.data.message);
                await this.saveData();
                this.getTemplateDetail();
            }
        },
        async saveData() {
            const { templateId, templateName } = this.$route.query;

            let data = this.receivedData;
            let passdata = {
                templateName: templateName,
                templateId: templateId,
                ...data,
                __v: this.sourceData.__v,
            };
            let res = await UpdateTicketTemplate(passdata);
            if (res.errcode != 0) return;
            this.$Message.info('修改成功');
        },
        test() {
            const message = { message: JSON.stringify(this.sourceData), timestamp: new Date() };
            this.$refs.iframe.contentWindow.postMessage(message, '*');
        },
    },
};
</script>
